import React from "react";
import Fab from "@material-ui/core/Fab";
import {DingWrapper} from "./DingWrapper";

export const DingFab = ({children, ...restProps}) => (
    <DingWrapper
        component={Fab}
        {...restProps}
    >
        {children}
    </DingWrapper>
)