import de from './de.json';
import en from './en.json';
import fr from './fr.json';
import it from './it.json';
import nl from './nl.json';
import pl from './pl.json';
import ro from './ro.json';
import tr from './tr.json';

export default {
  de,
  en,
  fr,
  it,
  nl,
  pl,
  ro,
  tr,
};
