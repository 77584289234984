(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("@virtaskdev/highlight-tools"), require("react-redux"), require("axios"), require("redux"));
	else if(typeof define === 'function' && define.amd)
		define("@virtaskdev/module-video-conferencing", ["react", "@virtaskdev/highlight-tools", "react-redux", "axios", "redux"], factory);
	else if(typeof exports === 'object')
		exports["@virtaskdev/module-video-conferencing"] = factory(require("react"), require("@virtaskdev/highlight-tools"), require("react-redux"), require("axios"), require("redux"));
	else
		root["@virtaskdev/module-video-conferencing"] = factory(root["react"], root["@virtaskdev/highlight-tools"], root["react-redux"], root["axios"], root["redux"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__7__, __WEBPACK_EXTERNAL_MODULE__31__, __WEBPACK_EXTERNAL_MODULE__44__) {
return 