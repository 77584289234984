import { get } from 'lodash';
import React from 'react';
import hat from 'hat';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import {
  contactsFetched,
  statusChanged,
  callStatusChanged,
  // incomingCall,
  socketConnected,
  callTimedOut,
  callRejected,
  contactStatusesChanged,
} from './actions';

import IncomingCall from './components/IncomingCall';
import OutgoingCall from './components/OutgoingCall';
import ActiveCall from './components/ActiveCall';
import {HOME_PATH} from "./index";

const cache = {}; // ToDo: Change to dexie later.
let callTimeout = null;

const dialSound = new Audio('/sounds/phone_dial.mp3');
dialSound.loop = true;

const busySound = new Audio('/sounds/phone_busy.mp3');
busySound.loop = true;

const ringSound = new Audio('/sounds/phone_ring.mp3');
ringSound.loop = true;

const stopAll = () => {
  dialSound.pause();
  busySound.pause();
  ringSound.pause();
};

const IsJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

const fetchContacts = (dispatch, getState) => {
  const state = getState();
  const settings = get(state, 'appsync.appSyncData.userSettings', {});
  const { videoConferencing } = settings;

  if (videoConferencing) {
    cache.contacts = videoConferencing.contacts || [];
    cache.contactDetails = videoConferencing.contactDetails || {};
    dispatch(
      contactsFetched(videoConferencing.contacts || [], videoConferencing.contactDetails || {}),
    );
  } else {
    // ToDo: dispatch a warning that video conferencing isn't configured
  }
};

export const getContacts = () => cache;

const addSocketListeners = (dispatch, getState, history) => {
  const rws = getState().shell.websocket;
  if(!rws) {
    setTimeout(() => {
      addSocketListeners(dispatch, getState, history);
    }, 500);
    return;
  }
  const contactDetails = getState()['video-conferencing']?.contactDetails || {};

  rws.addEventListener('open', (event) => {
    dispatch(statusChanged('online'));
    rws.sendStringified({ action: 'getContactsStatus', firstTime: true });
    console.log('Open:', event);
  });

  rws.addEventListener('message', (event) => {
    // if path is forbidden print Pong
    // forbidden paths send messages, but they dont count to AWS Lambda compute time
    // since they are rejected straight away
    if (event.data && event.data.includes('Forbidden')) {
      console.log(JSON.stringify({message: "FPong"}))
    } else {
      console.log('Message:', event.data);
    }
    if (IsJsonString(event.data)) {
      const parsedData = JSON.parse(event.data);
      if (parsedData.contacts && parsedData.contacts.length > 0) {
        const newContacts = {};
        parsedData.contacts.forEach((c) => {
          if (c.status === 'offline') {
            const state = getState();
            const currentState = get(state, 'video-conferencing', {});
            const { peer } = currentState;

            if (parsedData.username === peer) {
              dispatch(callStatusChanged('idle', ''));
              dispatch({
                type: 'shell/HIDE_MODAL',
              });
            }
            stopAll();
          }
          newContacts[c.contact] = { username: c.contact, status: c.status }
        });
        dispatch(contactStatusesChanged(newContacts));
      }
      if (parsedData.contact) {
        // prevent double connections with same username to send contact offline
        if (parsedData.status === 'offline') {
          rws.sendStringified({ action: 'getContactsStatus' });
        }
        if (parsedData.status === 'online') {
          rws.sendStringified({ action: 'getContactsStatus' });
        }
      }
      if (parsedData.callingMsg === 'call-connecting') {
        dispatch({ type: 'global/SHUT_UP' });
        dispatch({ type: 'avatar/STOP_LISTENING' });
        dispatch({ type: 'avatar/HIDE' });
        dispatch(callStatusChanged('connecting', parsedData.peer));
        dispatch({
          type: 'shell/HIDE_MODAL',
        });
        dispatch({
          type: 'shell/SHOW_MODAL',
          centerModalComponent: <OutgoingCall />,
          disableHideModal: true,
        });

        dialSound.play();

        callTimeout = setTimeout(() => {
          stopAll();
          dispatch(callTimedOut(parsedData.peer, rws));
        }, 60 * 3000); // ToDo: make timeout configurable via dashboard
      }

      if (parsedData.callingMsg === 'call-incoming') {
        const videoConferencing = get(getState(), 'video-conferencing', {});
        const { callStatus } = videoConferencing;

        if (callStatus === 'busy') {
          dispatch(callRejected(parsedData.peer, rws));
          stopAll();
        } else {
          dispatch({ type: 'global/SHUT_UP' });
          dispatch({ type: 'avatar/STOP_LISTENING' });
          dispatch({
            type: 'avatar/START_CONFIDENTIAL_MODE',
          });
          dispatch({
            type: 'avatar/TEMPORARY_HIDE',
          });
          dispatch(callStatusChanged('incoming', parsedData.peer));
          dispatch({
            type: 'shell/SHOW_MODAL',
            centerModalComponent: <IncomingCall />,
            disableHideModal: true,
          });
          stopAll();
          ringSound.play();
        }
      }

      if (parsedData.callingMsg === 'call-cancelled') {
        if (callTimeout) {
          clearTimeout(callTimeout);
        }
        dispatch({ type: 'global/RESUME' });
        dispatch({
          type: 'avatar/STOP_CONFIDENTIAL_MODE',
        });
        dispatch({
          type: 'avatar/RESTORE_VISIBILITY',
        });
        dispatch(callStatusChanged('idle', ''));
        dispatch({
          type: 'shell/HIDE_MODAL',
        });
        stopAll();
      }

      if (parsedData.callingMsg === 'call-missed') {
        const videoConferencing = get(getState(), 'video-conferencing', {});
        const { callStatus } = videoConferencing;
        const notificationId = hat();
        dispatch({
          type: 'avatar/RESTORE_VISIBILITY',
        });
        dispatch({
          type: 'telemetry/RECORD',
          data: {
            category: 'call',
            type: 'missedCall',
            meta: {
              peer: parsedData.peer,
            },
          },
        });
        const notification = () => dispatch({
          type: 'notifications/SHOW',
          notification: {
            id: notificationId,
            contents: (
              <FormattedMessage
                id="video-conferencing.missedCall"
                default="!!video-conferencing.missedCall"
                description="Missed call"
                values={{
                  contact: (() => {
                    const { peer } = parsedData;
                    const nickname = contactDetails?.[peer]?.nickname;
                    return nickname || peer;
                  })(),
                  time: moment().clone().format('HH:mm'),
                }}
              />
            ),
            onConfirm: () => {
              history.push('/video-conferencing');

              dispatch({
                type: 'notifications/EXPIRE',
                notificationId,
              });

              dispatch({
                type: 'telemetry/RECORD',
                data: {
                  category: 'call',
                  type: 'confirm',
                  meta: {
                    notificationId,
                  },
                },
              });
            },
            onCancel: () => {
              dispatch({
                type: 'notifications/EXPIRE',
                notificationId,
              });

              dispatch({
                type: 'telemetry/RECORD',
                data: {
                  category: 'call',
                  type: 'dismiss',
                  meta: {
                    notificationId,
                  },
                },
              });
            },
          },
        });

        if (callStatus === 'busy') {
          notification();
        }

        // Notify about missed call
        if (callStatus === 'incoming') {
          notification();
          dispatch({ type: 'global/RESUME' });
          dispatch(callStatusChanged('idle', ''));
          dispatch({
            type: 'shell/HIDE_MODAL',
          });
          stopAll();
        }
      }

      if (parsedData.callingMsg === 'call-accepted') {
        const videoConferencing = get(getState(), 'video-conferencing', {});
        const { callStatus } = videoConferencing;
        // incoming check is for remote peer, connecting is for local
        if (callStatus === 'incoming' || callStatus === 'connecting') {
          if (callTimeout) {
            clearTimeout(callTimeout);
          }
          dispatch(callStatusChanged('accepted', parsedData.peer));
          dispatch({
            type: 'avatar/START_CONFIDENTIAL_MODE',
          });
          dispatch({
            type: 'shell/TRIGGER_NAVIGATION',
            triggerNavigation: HOME_PATH
          });
          dispatch({
            type: 'shell/HIDE_MODAL',
          });
          dispatch({
            type: 'shell/SHOW_MODAL',
            centerModalComponent: <ActiveCall />,
            disableHideModal: true,
          });
          stopAll();
        }
      }

      if (parsedData.callingMsg === 'call-rejected') {
        dispatch({ type: 'global/RESUME' });
        dispatch(callStatusChanged('idle', ''));
        dispatch({
          type: 'avatar/RESTORE_VISIBILITY',
        });
        dispatch({
          type: 'shell/HIDE_MODAL',
        });
        stopAll();
      }

      if (parsedData.callingMsg === 'line-busy') {
        const videoConferencing = get(getState(), 'video-conferencing', {});
        const { callStatus } = videoConferencing;

        if (callStatus !== 'idle') {
          dispatch({ type: 'global/RESUME' });
          dispatch(callStatusChanged('idle', ''));
          dispatch({
            type: 'shell/HIDE_MODAL',
          });
          stopAll();
          busySound.play();
          setTimeout(() => stopAll(), 3000);
        } else {
          stopAll();
          busySound.play();
          setTimeout(() => stopAll(), 3000);
        }
      }
      if (parsedData.callingMsg === 'hanged-up') {
        dispatch({ type: 'global/RESUME' });
        dispatch(callStatusChanged('idle', ''));
        dispatch({
          type: 'shell/HIDE_MODAL',
        });
        dispatch({
          type: 'avatar/STOP_CONFIDENTIAL_MODE',
        });
        stopAll();
      }
    }
  });

  rws.addEventListener('reconnect', () => {
    dispatch(statusChanged('online'));
    rws.sendStringified({ action: 'getContactsStatus' });
  });

  rws.addEventListener('maximum', () => {});

  rws.addEventListener('close', (event) => {
    dispatch(statusChanged('offline'));
    console.log('Closed!', event);
  });

  rws.addEventListener('error', (event) => {
    dispatch(statusChanged('offline'));
    console.log('Error:', event);
  });

  dispatch(socketConnected(rws));
};

export const start = async (store, history) => {
  const { dispatch, getState } = store;
  fetchContacts(dispatch, getState);
  addSocketListeners(dispatch, getState, history);
};

export default {
  start,
  getContacts,
};
