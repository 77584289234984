import React from 'react';
import Phone from '@material-ui/icons/Phone';
import v from 'voca';
import translations from './translations';
import { start, getContacts } from './worker';
import reducer from './reducer';

import HelpToggle from './components/HelpToggle';

import asrLocales from './asr';
import ttsLocales from './tts';

export const ID = 'video-conferencing';
export const HOME_PATH = `/${ID}`;
export const HELP_PATH = `/${ID}/help`;

const init = ({ /*  locale, */ store, history }) => {
  start(store, history);
};

const icon = <Phone key={ID} style={{ fontSize: '100' }} />;
const Tray = () => <HelpToggle helpHref={HELP_PATH} backHref={HOME_PATH} />;

const asrExtensions = () => {
  const { contacts, contactDetails } = getContacts();
  const dictionary = contacts.map((c) => {
    const nickname = contactDetails[c].nickname || '';
    if (nickname) {
      return { word: v.latinise(nickname.toLowerCase()), tag: '#Contact' };
    }

    return { word: v.latinise(c.toLowerCase()), tag: '#Contact' };
  });

  return {
    dictionary,
    rules: [],
  };
};

export default {
  ID,
  init,
  tray: Tray,
  icon,
  reducer,
  translations,
  asrLocales,
  asrExtensions,
  ttsLocales,
};
