import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

export const DelayLink = props => {
  let [isTimeOut, setIsTimeout] = useState(false);
  const [timeoutId, setTimeoutId] = React.useState(null);
  let history = useHistory();
  const {
    replace,
    to,
    delay,
    onDelayStart,
    onDelayEnd,
    children,
    onClick,
  } = props;
  useEffect(() => {
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [timeoutId]);
  useEffect(() => {
    if (isTimeOut) {
      let delayTimeOut = setTimeout(() => {
        setIsTimeout(false);
        onDelayEnd();
        if (replace) {
          history.replace(to);
        } else {
          history.push(to);
        }
      }, delay);
      setTimeoutId(delayTimeOut);
      return () => {
        clearTimeout(delayTimeOut);
      };
    }
  }, [delay, history, onDelayEnd, replace, to, isTimeOut]);
  const handleClick = useCallback(
    e => {
      onDelayStart(e, to);
      if (e.defaultPrevented) {
        return;
      }
      e.preventDefault();
      setIsTimeout(true);
      onClick && onClick();
    },
    [to, onDelayStart],
  );

  return (
    <Link
      to={props.to}
      className={props.className}
      delay={props.delay}
      onClick={handleClick}
      replace={props.replace}
    >
      {children}
    </Link>
  );
};

DelayLink.defaultProps = {
  delay: 0,
  onDelayStart: () => {},
  onDelayEnd: () => {},
};
