import React from "react";
import Tab from '@material-ui/core/Tab';
import {DingWrapper} from "./DingWrapper";

export const DingTab = ({children, ...restProps}) => (
    <DingWrapper
        component={Tab}
        {...restProps}
    >
        {children}
    </DingWrapper>
)