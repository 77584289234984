import React, { useContext, useCallback } from 'react';
import { HighlightContext, getSound } from './highlightContext';

export const DingWrapper = ({
  component: Component,
  children,
  onClick,
  ...restProps
}) => {
  const context = useContext(HighlightContext);

  const playSound = useCallback(() => {
    const sound = getSound(context.useDing);
    if (context.useDing && sound) {
      sound.play();
    }
  }, [context]);

  if (!Component && !onClick && typeof children !== 'function') {
    throw new Error(
      'You need to pass component and onClick method props or render method to children for DingWrapper',
    );
  }

  if (typeof children === 'function') {
    return children({ onAction: playSound });
  }

  const handleClick = (...props) => {
    if (context.useDing) {
      playSound();
    }
    onClick && onClick(...props);
  };

  return (
      <Component
          onClick={handleClick}
          {...restProps}
      >
        {children}
      </Component>
  )
};
