import React from "react";
import Paper from "@material-ui/core/Paper";
import {DingWrapper} from "./DingWrapper";

export const DingPaper = ({children, ...restProps}) => (
    <DingWrapper
        component={Paper}
        {...restProps}
    >
        {children}
    </DingWrapper>
)