import React from "react";
import Button from "@material-ui/core/Button";
import {DingWrapper} from "./DingWrapper";

export const DingButton = ({children, ...restProps}) => (
    <DingWrapper
        component={Button}
        {...restProps}
    >
        {children}
    </DingWrapper>
)