import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import AccountCircle from 'mdi-material-ui/AccountCircle';
import PhoneHangup from 'mdi-material-ui/PhoneHangup';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { DingButton } from "@virtaskdev/highlight-tools";

import {
  callCancelled,
} from '../actions';

const styles = () => ({
  avatar: {
    width: '6em',
    height: '6em',
  },
  avatarIcon: {
    width: '5.3em',
    height: '5.3em',
    margin: '2em',
  },
  card: {
    transform: 'scale(1.5)',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cardControls: {
    display: 'flex',
    // flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  hangup: {
    background: 'rgba(255, 30, 0, .8)',
    borderRadius: 0,
    width: '100%',
  },
  buttonIcon: {
    marginRight: '.3em',
  },
  diallingText: {
    fontWeight: 100,
    padding: '.3em 0 .5em 0',
    opacity: '.8',
    textTransform: 'uppercase',
  },
  peer: {
    margin: '.5em 0',
    textTransform: 'capitalize',
  },
});

const cancelCall = (dispatch, peer, socket) => {
  dispatch(callCancelled(peer, socket));

  dispatch({
    type:'telemetry/RECORD',
    data:{
      category:'call',
      type:'cancel',
      meta:{
        peer:peer,
      },
    },
  });
};

const OutgoingCall = ({
  classes,
  peer,
  contactDetails,
  socket,
  dispatch,
}) => (
  <Card className={classes.card}>
    <CardContent className={classes.cardContent}>
      <Typography variant="h5" className={classes.diallingText}>
        <FormattedMessage
          id="video-conferencing.dialling"
          default="!!video-conferencing.dialling"
          description="Dialling"
        />
      </Typography>
      <Avatar className={classes.avatar}>
        <AccountCircle className={classes.avatarIcon} />
      </Avatar>
      <Typography variant="h4" className={classes.peer}>
        { 
          contactDetails[peer] && contactDetails[peer].nickname
          ? contactDetails[peer].nickname
          : peer
        }
      </Typography>
    </CardContent>
    <div className={classes.cardControls}>
      <DingButton onClick={() => cancelCall(dispatch, peer, socket)} className={classes.hangup}>
        <PhoneHangup className={classes.buttonIcon} />
        <FormattedMessage
          id="video-conferencing.cancel"
          default="!!video-conferencing.cancel"
          description="Cancel call"
        />
      </DingButton>
    </div>
  </Card>
);

const mapStateToProps = state => ({
  peer: state['video-conferencing'].peer,
  contactDetails: state['video-conferencing'].contactDetails,
  socket: state['video-conferencing'].socket,
});

export default connect(mapStateToProps)(withStyles(styles)(OutgoingCall));
