import hat from 'hat';
import v from 'voca';

import {
  CONTACTS_FETCHED,
  STATUS_CHANGED,
  INCOMING_CALL,
  CALL_INITIATED,
  CALL_STATUS_CHANGED,
  SOCKET_CONNECTED,
  CALL_CANCELLED,
  CALL_ACCEPTED,
  CALL_REJECTED,
  CALL_TIMED_OUT,
  CALL_HUNG_UP,
  CALL_INITIATED_ASR,
  RESET_TRIGGER,
  CONTACT_STATUSES_CHANGED,
} from './actionTypes';

const initialState = {
  contacts: [],
  contactDetails: {},
  status: 'offline',
  callStatus: 'idle',
  peer: '',
  socket: null,
  shouldWarn: '',
  warningType: '',
  triggerCall: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CONTACTS_FETCHED:
      return Object.assign({}, state, {
        contacts: action.contacts.map(c => ({ username: c, status: 'offline' })),
        contactDetails: action.contactDetails,
      });
    case STATUS_CHANGED:
      return Object.assign({}, state, {
        status: action.status,
      });
    case CONTACT_STATUSES_CHANGED:
      return {
        ...state,
        contacts: state.contacts.map((contact) => {
          if (action.contacts[contact.username]) return action.contacts[contact.username];
          return contact;
        })
      };
    case INCOMING_CALL:
      return Object.assign({}, state, {
        peer: action.peer,
      });
    case CALL_INITIATED:
      return Object.assign({}, state, {
        peer: action.peer,
      });
    case CALL_STATUS_CHANGED:
      return Object.assign({}, state, {
        callStatus: action.callStatus,
        peer: action.peer,
      });
    case SOCKET_CONNECTED:
      return Object.assign({}, state, {
        socket: action.socket,
        status: 'online',
      });
    case CALL_CANCELLED:
      return Object.assign({}, state, {
        peer: action.peer,
      });
    case CALL_ACCEPTED:
      return Object.assign({}, state, {
        peer: action.peer,
      });
    case CALL_REJECTED:
      return Object.assign({}, state, {
        peer: action.peer,
      });
    case CALL_TIMED_OUT:
      return Object.assign({}, state, {
        peer: action.peer,
      });
    case CALL_HUNG_UP:
      return Object.assign({}, state, {
        peer: action.peer,
      });
    case CALL_INITIATED_ASR: {
      const peer = action.value.entities.find(e => e.entity === '#Contact');

      if (peer) {
        // this works for contacts that use the username
        const contact = state.contacts.find(
          c => c.username.toLowerCase() === peer.utteranceText.toLowerCase().trim(),
        );

        if (contact) {
          if (contact.status === 'online') {
            return Object.assign({}, state, {
              peer: peer.utteranceText.toLowerCase().trim(),
              triggerCall: hat(),
            });
          }
        }
        
        // afterwards, we try to look by nickname, if lookup by contact failed
        const findByNickname = Object.keys(state.contactDetails).map(k => {
          return state.contactDetails[k];
        }).find(c => c.nickname && v.latinise(c.nickname.toLowerCase()) === peer.utteranceText.toLowerCase().trim());

        if (findByNickname) {
          const contactByNickname = state.contacts.find(
            c => c.username.toLowerCase() === findByNickname.id
          );

          if (contactByNickname) {
            if (contactByNickname.status === 'online') {
              return Object.assign({}, state, {
                peer: contactByNickname.username,
                triggerCall: hat(),
              });
            }
          }
        }

        console.log('should return warning');

        return Object.assign({}, state, {
          shouldWarn: hat(),
          warningType: 'contactOffline',
          peer: peer.utteranceText.toLowerCase().trim(),
        });
      }

      return Object.assign({}, state, {
        shouldWarn: hat(),
        warningType: 'contactNotFound',
      });
    }
    case RESET_TRIGGER:
      return Object.assign({}, state, {
        triggerCall: action.triggerCall,
        shouldWarn: action.shouldWarn,
      });
    default:
      return Object.assign({}, state);
  }
};
