import React from 'react';
import { withRouter } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HelpIcon from '@material-ui/icons/Help';
import { Control } from './Control';

const HelpToggle = ({ backHref, helpHref, location }) => {
  const isHelp = location.pathname === helpHref;
  const href = isHelp ? backHref : helpHref;
  const icon = isHelp ? ArrowBackIcon : HelpIcon;

  return (
    <Control href={href} icon={icon} />
  );
};

export default withRouter(HelpToggle);
