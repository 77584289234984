import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { connect as twilioConnect } from 'twilio-video';
import axios from 'axios';
import PhoneHangup from 'mdi-material-ui/PhoneHangup';
import CameraOff from 'mdi-material-ui/CameraOff';
import Camera from 'mdi-material-ui/Camera';
import MicrophoneOff from 'mdi-material-ui/MicrophoneOff';
import Microphone from 'mdi-material-ui/Microphone';
import { get } from 'lodash';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DingFab } from "@virtaskdev/highlight-tools";
import { callHungUp, callStatusChanged } from '../actions';

import './twilio.css';

const styles = (theme) => ({
  paper: {
    margin: 'unset',
    position: 'unset',
    overflowY: 'unset',
  },
  paperNonFullscreen: {
    width: '50%',
    height: '72%',
    margin: '0',
    maxWidth: '50%',
    maxHeight: '72%',
    '@media (max-width: 1024px)': {
      width: '70%',
      height: '80%',
      maxWidth: '70%',
      maxHeight: '80%',
    },
    '@media (max-width: 650px)': {
      width: '90%',
      height: '80%',
      maxWidth: '90%',
      maxHeight: '80%',
    }
  },
  contentRoot: {
    padding: '0!important',
    position: 'relative',
  },
  contentBackground: {
    background: 'rgba(0, 0, 0, 0.7)',
  },
  wrap: {
    background: 'transparent',
  },
  wrapBorder: {
    border: '40px solid rgba(0,0,0, .3)',
    '@media (max-width: 650px)': {
      borderWidth: '10px',
    }
  },
  remote: {
    minHeight: '30vh',
    textAlign: 'center',
  },
  local: {
    display: 'flex',
    background: 'rgba(0,0,0, .3)',
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(0, 0, 0, .9)',
    padding: '2.1vh!important',
  },
  control: {
    marginRight: '2rem',
    transform: 'scale(1.4)',
    '@media (max-width: 650px)': {
      marginRight: '.5rem',
      transform: 'scale(1)',
    },
    '&:last-child': {
      marginRight: 0
    }
  },
  hangup: {
    background: 'rgba(255, 30, 0, .8)',
  },
  contactLabel: {
    background: 'rgba(0,0,0, .8)',
    color: theme.palette.primary.light,
    padding: '5px',
    fontSize: '1.5em',
    textAlign: 'center',
  },
  contactStatus: {
    color: 'white',
  },
});

class ActiveCall extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: null,
      participants: [],
      tracks: [],
      room: null,
      microphoneMuted: false,
      videoDisabled: false,
      fullscreen: false,
    };
  }

  componentDidMount() {
    this.connectToTwilio();
  }

  componentWillUnmount() {
    const { tracks, room } = this.state;

    if (room) {
      room.disconnect();
    }

    tracks.forEach((t) => {
      if (t.stop) t.stop();
    });
  }

  trackSubscribed = (track, target) => {
    const { tracks } = this.state;

    if (target) {
      target.appendChild(track.attach());
    }
    this.setState({
      tracks: [...tracks, track],
    });
  };

  trackUnsubscribed = (track) => {
    const { tracks } = this.state;

    track.detach().forEach((el) => el.remove());
    this.setState({
      tracks: tracks.filter((t) => {
        const identifier = t.id || t.sid;

        return !(track.sid === identifier || track.id === identifier);
      }),
    });
  };

  participantConnected = (participant, participants, username) => {
    this.setState({
      participants: [...participants, participant.identity],
    });

    participant.on('trackSubscribed', (track) => {
      if (participant.identity === username) {
        this.trackSubscribed(track, this.local);
      } else {
        this.trackSubscribed(track, this.remote);
      }
    });

    participant.on('trackUnsubscribed', (track) => {
      if (participant.identity === username) {
        this.trackUnsubscribed(track, this.local);
      } else {
        this.trackUnsubscribed(track, this.remote);
      }
    });

    participant.tracks.forEach((publication) => {
      if (publication.track) {
        if (participant.identity === username) {
          this.trackSubscribed(publication.track, this.local);
        } else {
          this.trackSubscribed(publication.track, this.remote);
        }
      }
    });
  };

  getFrontCamera = async () => {
    let stream;
    try {
      /* we need to get an access to cameras if we want to use enumerateDevices */
      stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: 'user' },
      });
      const devices = await navigator.mediaDevices.enumerateDevices();
      const cameras = devices.filter((x) => x.kind === 'videoinput');
      const frontCamera = cameras.find((x) =>
        x.label.toLowerCase().includes('front')
      );

      if (frontCamera) {
        return {
          deviceId: { exact: frontCamera.deviceId },
        };
      }
    } catch (err) {
      console.error(err);
    } finally {
      if (stream) {
        stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    }
    return { video: { facingMode: 'user' } };
  };

  connectToTwilio = async () => {
    const { username, peer, dispatch, audioOnly } = this.props;

    const res = await axios({
      url:
        'https://jfj2zfyltnfhho5xmksmzq5w6u.appsync-api.eu-central-1.amazonaws.com/graphql',
      method: 'post',
      withCognitoAuth: true,
      data: {
        query: `query {
          twilioToken
        }`,
      },
    });

    const token = get(res, 'data.data.twilioToken', null);

    const roomName = [username, peer].sort().join(':');

    if (token && token.length > 1) {
      const videoOptions = await this.getFrontCamera();
      const room = await twilioConnect(token, {
        name: roomName,
        audio: true,
        video: audioOnly ? false : videoOptions,
      });

      const participants = (() => {
        const participantsArray = [];
        room.participants.forEach((p) => participantsArray.push(p.identity));
        return participantsArray;
      })();

      this.setState({
        token,
        room,
        participants,
      });

      dispatch(callStatusChanged('busy', peer));

      this.participantConnected(room.localParticipant, participants, username);

      room.participants.forEach((p) =>
        this.participantConnected(p, participants, username)
      );
      room.on('participantConnected', (p) =>
        this.participantConnected(p, participants, username)
      );
      room.on('participantDisconnected', () => {
        // dispatch call state to idle and peer to ''
        // hide modal
      });
      room.once('disconnected', () => {
        // dispatch call state to idle and peer to ''
        // hide modal
      });
    }
  };

  hangup = (state, props) => {
    const { dispatch, socket, peer } = props;
    const { room, tracks } = state;

    if (room) room.disconnect();
    tracks.forEach((t) => {
      if (t.stop) t.stop();
    });

    dispatch({
      type: 'telemetry/RECORD',
      data: {
        category: 'call',
        type: 'hangup',
        meta: {
          peer,
        },
      },
    });

    dispatch(callHungUp(peer, socket));
  };

  toggleMicrophone = (state) => {
    const { room, microphoneMuted } = state;

    room.localParticipant.audioTracks.forEach((t) => {
      if (microphoneMuted) {
        t.track.enable();
        this.setState({
          microphoneMuted: false,
        });
      } else {
        t.track.disable();
        this.setState({
          microphoneMuted: true,
        });
      }
    });
  };

  toggleVideo = (state) => {
    const { room, videoDisabled } = state;

    room.localParticipant.videoTracks.forEach((t) => {
      if (videoDisabled) {
        t.track.enable();
        this.setState({
          videoDisabled: false,
        });
      } else {
        t.track.disable();
        this.setState({
          videoDisabled: true,
        });
      }
    });
  };

  render() {
    const { peer, classes, contactDetails } = this.props;
    const {
      participants,
      videoDisabled,
      microphoneMuted,
      fullscreen,
    } = this.state;
    const isConnected = participants.includes(peer);
    const title = contactDetails[peer] && contactDetails[peer].nickname
        ? contactDetails[peer].nickname
        : peer;
    return (
      <Dialog
        classes={{
          paperScrollPaper: `${classes.wrap} ${
            !fullscreen ? classes.wrapBorder : ''
          }`,
          paper: `${classes.paper} ${
            !fullscreen ? classes.paperNonFullscreen : ''
          }`,
        }}
        PaperComponent="div"
        fullScreen={fullscreen}
        open
        BackdropProps={{ invisible: true }}>
        <DialogTitle
          disableTypography
          classes={{
            root: `${classes.contentRoot} ${classes.contactLabel}`,
          }}>
          {`${title}${isConnected ? ' - connected' : ''}`}
        </DialogTitle>
        <DialogContent
          classes={{
            root: `${classes.contentRoot} ${classes.contentBackground}`,
          }}>
          <div
            ref={(DOMNodeRef) => {
              this.remote = DOMNodeRef;
            }}
            id="remote-media"
            className={classes.remote}
          />
          <div
            ref={(DOMNodeRef) => {
              this.local = DOMNodeRef;
            }}
            id="local-media"
            className={classes.local}
          />
        </DialogContent>
        <DialogActions
          classes={{
            root: `${classes.contentRoot} ${classes.controls}`,
          }}>
          <DingFab
            className={[classes.control, classes.hangup].join(' ')}
            onClick={() => this.hangup(this.state, this.props)}
            size="large">
            <PhoneHangup />
          </DingFab>
          <DingFab
            size="large"
            className={classes.control}
            onClick={() => this.toggleMicrophone(this.state)}>
            {microphoneMuted ? <MicrophoneOff /> : <Microphone />}
          </DingFab>
          <DingFab
            size="large"
            className={classes.control}
            onClick={() => this.toggleVideo(this.state)}>
            {videoDisabled ? <CameraOff /> : <Camera />}
          </DingFab>
          <DingFab
            size="large"
            className={classes.control}
            onClick={() => this.setState({ fullscreen: !fullscreen })}>
            {fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </DingFab>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  username: state.appsync.appSyncData.userSettings.username,
  peer: state['video-conferencing'].peer,
  contactDetails: state['video-conferencing'].contactDetails,
  socket: state['video-conferencing'].socket,
  audioOnly: state.appsync.appSyncData.userSettings.videoConferencing.audioOnly,
});

export default connect(mapStateToProps)(withStyles(styles)(ActiveCall));
