(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-router-dom"));
	else if(typeof define === 'function' && define.amd)
		define("@virtaskdev/module-highlight-tools", ["react", "react-router-dom"], factory);
	else if(typeof exports === 'object')
		exports["@virtaskdev/module-highlight-tools"] = factory(require("react"), require("react-router-dom"));
	else
		root["@virtaskdev/module-highlight-tools"] = factory(root["react"], root["react-router-dom"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE_react__, __WEBPACK_EXTERNAL_MODULE_react_router_dom__) {
return 