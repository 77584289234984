import React from 'react';

let sounds = {};

export const HighlightContext = React.createContext({
  useDing: null,
});

export const initSounds = (passedSounds) => {
  sounds = passedSounds;
}

export const getSound = (soundName) => {
  const sound = sounds[soundName];
  if(!sound) {
    console.error(`You need to init highlight-tools by initSounds method and pass there key-value storage with Audio sounds. Or ${soundName} doesn't exist in sounds`)
    return null;
  }
  return sounds[soundName];
}

