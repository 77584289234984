import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { DingButton } from "@virtaskdev/highlight-tools";

const ControlButton = ({
  href,
  classes,
  history,
  icon: Icon,
  onNavigate,
}) => (
  <DingButton
    size="large"
    variant="contained"
    className={classes.button}
    onClick={() => {
      onNavigate(href);
      history.push(href);
    }}
  >
    <Icon color="action" className={classes.icon} />
  </DingButton>
);

const styles = () => ({
  button: {
    padding: '.2rem',
  },
  icon: {
    width: '2.9em',
    height: '2.9em',
  },
});

const navigateTo = href => ({
  type: 'telemetry/RECORD',
  data: {
    category: 'navigation',
    type: 'touch',
    meta: {
      target: href,
      source: 'taskbar',
    },
  },
});

export const Control = compose(
  connect(
    null,
    {
      onNavigate: navigateTo,
    },
  ),
  withRouter,
  withStyles(styles),
)(ControlButton);

export default (Icon, href) => props => <Control icon={Icon} href={href} {...props} />;
