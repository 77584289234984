import React from 'react';
import {DingWrapper} from "./DingWrapper";

export const withDingHoc = (Component) => {
    return class extends React.PureComponent {
        render() {
            return (
                <DingWrapper>
                    {
                        ({onAction}) => (
                            <Component {...this.props} onAction={onAction} />
                        )
                    }
                </DingWrapper>
            )
        }
    }
}
