import {
  CONTACTS_FETCHED,
  STATUS_CHANGED,
  INCOMING_CALL,
  CALL_INITIATED,
  CALL_STATUS_CHANGED,
  SOCKET_CONNECTED,
  CALL_ACCEPTED,
  CALL_REJECTED,
  CALL_CANCELLED,
  CALL_TIMED_OUT,
  CALL_HUNG_UP,
  RESET_TRIGGER,
  CONTACT_STATUSES_CHANGED
} from './actionTypes';

export const socketConnected = socket => ({
  type: SOCKET_CONNECTED,
  socket,
});

export const contactsFetched = (contacts, contactDetails) => ({
  type: CONTACTS_FETCHED,
  contacts,
  contactDetails,
});

export const statusChanged = status => ({
  type: STATUS_CHANGED,
  status,
});

export const contactStatusesChanged = (contacts) => ({
  type: CONTACT_STATUSES_CHANGED,
  contacts,
});

export const incomingCall = peer => ({
  type: INCOMING_CALL,
  peer,
});

export const callInitiated = (peer, socket) => {
  socket.sendStringified({ action: 'calling', peer, message: 'start-call' });

  return {
    type: CALL_INITIATED,
    peer,
  };
};

export const callAccepted = (peer, socket) => {
  socket.sendStringified({
    action: 'calling',
    peer,
    message: 'accept-call',
  });

  return {
    type: CALL_ACCEPTED,
    peer,
  };
};

export const callRejected = (peer, socket) => {
  socket.sendStringified({
    action: 'calling',
    peer,
    message: 'reject-call',
  });

  return {
    type: CALL_REJECTED,
    peer,
  };
};

export const callCancelled = (peer, socket) => {
  socket.sendStringified({
    action: 'calling',
    peer,
    message: 'cancel-call',
  });

  return {
    type: CALL_CANCELLED,
    peer,
  };
};

export const callTimedOut = (peer, socket) => {
  socket.sendStringified({
    action: 'calling',
    peer,
    message: 'call-timeout',
  });

  return {
    type: CALL_TIMED_OUT,
    peer,
  };
};

export const callHungUp = (peer, socket) => {
  socket.sendStringified({
    action: 'calling',
    peer,
    message: 'hang-up',
  });

  return {
    type: CALL_HUNG_UP,
    peer,
  };
};

export const callStatusChanged = (callStatus, peer) => ({
  type: CALL_STATUS_CHANGED,
  callStatus,
  peer,
});

export const resetTrigger = () => ({
  type: RESET_TRIGGER,
  triggerCall: '',
  shouldWarn: '',
});

export default {
  contactsFetched,
  statusChanged,
  contactStatusesChanged,
  incomingCall,
  callInitiated,
  callStatusChanged,
  socketConnected,
  callAccepted,
};
