export const CONTACTS_FETCHED = 'video-conferencing/CONTACTS_FETCHED';
export const STATUS_CHANGED = 'video-conferencing/STATUS_CHANGED';
export const CONTACT_STATUSES_CHANGED = 'video-conferencing/CONTACT_STATUSES_CHANGED';
export const INCOMING_CALL = 'video-conferencing/INCOMING_CALL';
export const CALL_INITIATED = 'video-conferencing/CALL_INITIATED';
export const CALL_STATUS_CHANGED = 'video-conferencing/CALL_STATUS_CHANGED';
export const SOCKET_CONNECTED = 'video-conferencing/SOCKET_CONNECTED';
export const CALL_ACCEPTED = 'video-conferencing/CALL_ACCEPTED';
export const CALL_REJECTED = 'video-conferencing/CALL_REJECTED';
export const CALL_CANCELLED = 'video-conferencing/CALL_CANCELLED';
export const CALL_HUNG_UP = 'video-conferencing/CALL_HUNG_UP';
export const CALL_TIMED_OUT = 'video-conferencing/CALL_TIMED_OUT';
export const CALL_INITIATED_ASR = 'video-conferencing/ASR/INITIATE_CALL';
export const RESET_TRIGGER = 'video-conferencing/RESET_TRIGGER';

export default {
  CONTACTS_FETCHED,
  STATUS_CHANGED,
  CONTACT_STATUSES_CHANGED,
  INCOMING_CALL,
  CALL_INITIATED,
  CALL_STATUS_CHANGED,
  SOCKET_CONNECTED,
  CALL_ACCEPTED,
  CALL_REJECTED,
  CALL_CANCELLED,
  CALL_TIMED_OUT,
  CALL_HUNG_UP,
  CALL_INITIATED_ASR,
};
